.manufacturer,
.total,
.notItem {
	background: var(--white);
	padding: 20px;
	border: 0;
	border-radius: 10px;
	margin-bottom: 20px;
}

.manufacturer {
	border: 2px solid var(--gray);
	border-radius: 10px;
}

.manufacturerName {
	padding-bottom: 20px;
	font-size: 18px;
	border-bottom: 2px solid var(--orange);
}

.item {
	display: flex;
	flex-direction: row;
	padding: 10px;
	border-bottom: 2px solid var(--orange);
	margin-bottom: 10px;
}

.item .img {
	/* width: 100%;
	max-width: 150px;
	max-height: 150px; */
	height: auto;
	padding: 10px;
}

.parts {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.title,
.vendorCode,
.price,
.allPrice {
	font-weight: bold;
}

.title span,
.vendorCode span,
.price span,
.allPrice span {
	font-weight: normal;
}

.buttonBlock {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.trash {
	margin-left: auto;
	cursor: pointer;
	margin-bottom: 10px;
}

.trash:hover {
	fill: var(--red);
}

.buttonPick {
	display: flex;
	margin: 0 auto;
}

.transparent {
	background: var(--gray);
	cursor: not-allowed;
}

.input {
	border: none;
	outline: none;
	text-align: center;
	width: 45px;
	height: 24px;
}

.total {
	display: flex;
	justify-content: space-between;
}

.notItem {
	font-size: 20px;
}

@media (max-width: 600px) {
	.item {
		flex-direction: column;
		align-items: center;
	}

	.parts {
		display: flex;
		flex-direction: column;
	}
}
