.info {
	display: grid;
	grid-template-columns: auto 1fr;
	padding: 10px;
	column-gap: 20px;
	position: relative;
}

.text {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	width: 100%;
}

.text h1 {
	margin: 7px 0;
}

.info button {
	width: 50%;
	margin: 20px auto 0;
}
.img {
	width: 100%;
	max-width: 400px;
	height: auto;
}

.noImg {
	width: 200px !important;
}

@media screen and (max-width: 700px) {
	.info {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.img {
		max-width: 150px;
		max-height: 150px;
		height: auto;
		align-self: center;
	}
	.text h1,
	p,
	span {
		font-size: medium;
	}
}
