:root {
	--red: #ff0000;
	--green: #59ab6e;
	--orange: #e37639e6;
	--gray: #d3d3d3;
	--gray-light: #e9eef5;
	--black: #212934;
	--white: #fff;
}

* {
	margin: 0;
	padding: 0;
	font-family: "Roboto", sans-serif;
	font-size: 18px;
}

body {
	background: #fff;
}

a {
	text-decoration: none;
}

.modal {
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background: var(--white);
	min-width: 50%;
	width: max-content;
	max-width: 75%;
	border: 0;
	border-radius: 10px;
}

.modal .modal__inner {
	padding: 20px;
}

.modal::backdrop {
	background: rgba(0, 0, 0, 0.5);
}

.modal .headerModal {
	padding: 5px;
	border-bottom: 1px solid #000;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.modal .headerModal p {
	white-space: nowrap;
	overflow: hidden;
	padding: 5px;
	text-overflow: ellipsis;
}

.modal .headerModal .closeModal {
	min-height: 18px;
	min-width: 18px;
	cursor: pointer;
}

.modal .bodyModal {
	padding: 5px;
}
