.content {
	display: flex;
	flex-direction: column;
	width: max-content;
	justify-content: center;
	height: 100%;
	margin: auto;
	padding-bottom: 30px;
}

.addUser {
	display: flex;
	flex-direction: column;
}

.addUser h2 {
	text-align: center;
	font-size: 25px;
	font-weight: bold;
	margin-bottom: 15px;
}

.addUser .required::after {
	content: "*";
	color: red;
}

.inputBlock {
	display: flex;
	flex-direction: column;
	margin: 5px 0;
}

.inputBlock label {
	width: 130px;
	padding-right: 20px;
}

.inputBlock input {
	width: 100%;
}

.errorInput p {
	margin-top: 2px;
}

.errorInput p,
.errorInput label {
	color: red;
}

.errorInput input {
	border: 1px solid red;
}

.btnBlock {
	display: flex;
	flex-direction: row;
}

.btnBlock button {
	width: 100%;
	margin: 0 20px;
}

@media (max-width: 600px) {
	.content {
		max-width: 100%;
	}

	.inputBlock input {
		width: auto;
	}
}
