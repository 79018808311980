.containerTop h1 {
	margin-bottom: 0.5rem;
	font-weight: 400;
	line-height: 1.2;
	font-size: 40px;
}

.descHead,
.containerTop,
.factsItems {
	text-align: center;
	font-weight: 300;
	font-size: 18px;
}

.descHead h2 {
	font-size: 48px;
	font-weight: 400;
	margin-bottom: 10px;
}

.containerTop {
	margin: 100px 100px 150px 100px;
}

.facts {
	display: grid;
	grid-template-columns: repeat(auto-fill, 290px);
	justify-content: center;
	justify-items: center;
}

.factsItems {
	height: 150px;
	width: 250px;
	text-align: center;
	margin: 20px;
}

.map {
	text-align: center;
	margin: 15px 10px;
}

.map h2 {
	font-size: 48px;
	font-weight: 200;
	margin-bottom: 15px;
}

@media (max-width: 600px) {
	.containerTop {
		margin: 50px 0;
	}
}
