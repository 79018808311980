.main,
.notOrders {
	background: var(--white);
	padding: 20px;
	border: 0;
	border-radius: 10px;
	margin-bottom: 20px;
}

.orderList {
	padding: 10px;
	margin-bottom: 20px;
	border: 2px solid var(--gray);
	border-radius: 0.4rem;
	box-shadow: rgb(0 0 0 / 10%) 0 0.5rem 0.5rem !important;
}

.order {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.title {
	display: flex;
	flex-direction: column;
}

.items {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 10px;
}

.items img {
	width: 100%;
	max-width: 150px;
	height: auto;
}

.desc,
.title {
	margin-bottom: 10px;
	font-weight: bold;
}

.desc span,
.title span {
	font-weight: normal;
}

.notOrders {
	font-size: 20px;
}

@media (max-width: 600px) {
	.items {
		flex-direction: column;
		align-items: center;
	}
}
