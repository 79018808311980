.container {
	padding: 20px;
	border: 0;
	border-radius: 10px;
	margin-bottom: 20px;
}

.userList {
	padding: 10px;
	margin-bottom: 20px;
	border: 2px solid var(--gray);
	border-radius: 0.4rem;
	box-shadow: rgb(0 0 0 / 10%) 0 0.5rem 0.5rem !important;
}

.user {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.title {
	display: flex;
}

.container .items p {
	margin: 0 15px 10px 0;
}

.desc {
	margin-bottom: 10px;
}

.items button {
	margin: 5px 5px;
}
