.h1 {
	font-size: xx-large;
	font-weight: bold;
	text-align: center;
}

.disabled {
	pointer-events: none;
	background-color: rgb(0, 0, 0, 0.03);
}

.disabledWithoutBackground {
	pointer-events: none;
}

.mainDiv {
	display: flex;
	flex-direction: column;
	grid-template-rows: min-content auto;
}

.centerWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	text-align: center;
	padding: 20px;
	box-sizing: border-box;
}

.divData {
	display: grid;
	grid-template-columns: min-content auto;
	column-gap: 20px;
}

.lineProductsDiv {
	display: flex;
	flex-direction: column;
}

.gridProductsDiv {
	display: grid;
	grid-template-columns: repeat(auto-fill, 200px);
	gap: 0 10px;
	justify-items: center;
	justify-content: center;
}

.searchDivGrid {
	display: grid;
	grid-template-columns: auto min-content;
}

.search {
	padding: 7px;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	height: 60px;
}
.view {
	margin-right: 5px;
}
.view:hover {
	color: var(--orange) !important;
}

.active {
	color: var(--orange) !important;
}

.loader {
	display: flex;
	justify-content: center;
}

.btnBlock {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.btnBlock button {
	margin: 5px 0;
}

.displayNone {
	display: none;
}

.viewType {
	margin-left: 30px;
}

.filterBtn {
	display: none;
}

.ldsRing {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.ldsRing div {
	animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 40px 40px;
}

.ldsRing div:after {
	content: " ";
	display: block;
	position: absolute;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background: var(--black);
	margin: -4px 0 0 -4px;
}

.ldsRing div:nth-child(1) {
	animation-delay: -0.036s;
}

.ldsRing div:nth-child(1):after {
	top: 63px;
	left: 63px;
}

.ldsRing div:nth-child(2) {
	animation-delay: -0.072s;
}

.ldsRing div:nth-child(2):after {
	top: 68px;
	left: 56px;
}

.ldsRing div:nth-child(3) {
	animation-delay: -0.108s;
}

.ldsRing div:nth-child(3):after {
	top: 71px;
	left: 48px;
}

.ldsRing div:nth-child(4) {
	animation-delay: -0.144s;
}

.ldsRing div:nth-child(4):after {
	top: 72px;
	left: 40px;
}

.ldsRing div:nth-child(5) {
	animation-delay: -0.18s;
}

.ldsRing div:nth-child(5):after {
	top: 71px;
	left: 32px;
}

.ldsRing div:nth-child(6) {
	animation-delay: -0.216s;
}

.ldsRing div:nth-child(6):after {
	top: 68px;
	left: 24px;
}

.ldsRing div:nth-child(7) {
	animation-delay: -0.252s;
}

.ldsRing div:nth-child(7):after {
	top: 63px;
	left: 17px;
}

.ldsRing div:nth-child(8) {
	animation-delay: -0.288s;
}

.ldsRing div:nth-child(8):after {
	top: 56px;
	left: 12px;
}

@keyframes ldsRing {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@media screen and (max-width: 1080px) {
	.viewType {
		display: none;
	}

	.searchDivGrid {
		grid-template-columns: auto;
	}
}

@media (max-width: 700px) {
	.search input {
		width: 100%;
	}

	.filterBtn {
		display: block;
	}

	.divData {
		grid-template-columns: auto;
	}
}

.manufacturerFilter {
	position: sticky;
	top: 10px;
	bottom: 10px;
	margin-top: -70px;
	margin-left: -20px;
	background-color: white;
	display: flex;
	flex-direction: column;
	visibility: visible;
	width: 230px;
	height: 80vh;
	transition: 350ms;
}

.manufacturerLabel {
	overflow: auto;
	margin-bottom: 10px;
}

.manufacturerFilter button {
	margin: 0 auto;
}

.div {
	display: grid;
	grid-template-columns: 1fr 20px;
	gap: 5px;
	margin: 7px;
	padding-bottom: 5px;
	border-bottom: 2px solid var(--gray);
}

.div input {
	width: 20px !important;
	height: 20px !important;
}

.leftPanelButton {
	width: max-content;
	visibility: collapse;
	display: flex;
	justify-content: center;
	align-items: center;
}
.cleanAllButton {
	display: block;
	max-width: max-content;
	align-self: center;
}
.cleanAllButtonNone {
	display: none;
}
.buttonText {
	text-align: center;
}
.marginBottom {
	margin: 5px;
	margin-bottom: 10px;
}
@media screen and (max-width: 700px) {
	.leftPanelButton {
		visibility: visible !important;
	}

	.manufacturerFilter {
		margin-top: 0px;
		position: absolute;
		box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5rem 1rem !important;
	}
}
/* @media screen and (min-width: 701px) {
	.manufacturerFilter.close {
		visibility: visible !important;
		width: 230px;
	}
} */

.manufacturerLabelMob {
	background: #fff;
	width: 100%;
	position: fixed !important;
	left: 0;
	top: 0;
	display: flex;
	overflow: auto;
	flex-direction: column;
	visibility: visible;
	height: 100vh;
	z-index: 999;
}

.manufacturerLabelMob .label {
	margin: 10px;
	margin-top: 50px;
}

.manufacturerLabelMob .close {
	position: absolute;
	right: 10px;
	top: 10px;
}
