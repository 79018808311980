.container {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: var(--white);
	border: 0;
	border-radius: 15px;
}

.container p {
	font-size: 25px;
	font-weight: bold;
}

.container button {
	margin-top: 10px;
}
