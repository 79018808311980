.loader {
	display: flex;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	justify-content: center;
	align-items: center;
	background: var(--white);
	z-index: 1059;
}

.ldsRing {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.ldsRing div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid var(--black);
	border-radius: 50%;
	animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: var(--black) transparent transparent transparent;
}
.ldsRing div:nth-child(1) {
	animation-delay: -0.45s;
}
.ldsRing div:nth-child(2) {
	animation-delay: -0.3s;
}
.ldsRing div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes ldsRing {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.login {
	background: #f2f5fa;
}

.manufacturer {
	background: rgba(255, 255, 255, 0.5) !important;
	opacity: 0.4;
}
