.gridBlock {
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
	padding: 15px;
	border: 2px solid var(--gray);
	border-radius: 10px;
	box-shadow: rgb(0 0 0 / 10%) 0px 0.5rem 0.5rem !important;
}

.info {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.imgLine {
	width: 100%;
	max-width: 100px;
	max-height: 100px;
	height: auto;
	margin: auto 0;
}

.img {
	width: 100%;
	max-width: 100px;
	max-height: 100px;
	height: auto;
	margin: auto 0;
}
.topDataLine {
	display: grid;
	grid-template-rows: 70% 30%;
	grid-template-columns: 100%;
	row-gap: 10px;
}
.topData {
	display: grid;
	grid-template-rows: 70% 30%;
	grid-template-columns: 100%;
	row-gap: 10px;
}

.name {
	overflow-wrap: break-word;
	font-size: large;
	font-weight: bold;
	text-align: left;
}
.buttonLine {
	min-width: 150px;
	height: 35px;
	width: max-content;
	margin: 5px;
}
.button {
	min-width: 150px;
	height: 35px;
	width: max-content;
	margin: 5px;
}

.buttonGrid {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
}

.buyDiv {
	width: max-content;
	display: flex;
	align-items: center;
}
.centerWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	text-align: center;
	padding: 5px;
	box-sizing: border-box;
}

.table {
	font-size: 14px;
	background: white;
	width: 100%;
	border-collapse: collapse;
	text-align: left;
}

.thead {
	font-weight: bold;
	color: black;
	border-bottom: 2px solid var(--orange);
	padding: 10px 8px;
}

.td {
	color: black;
	padding: 9px 8px;
	transition: 0.3s linear;
	text-align: center;
	vertical-align: middle;
}

.blockMainDiv {
	display: grid;
	grid-template-rows: auto auto auto auto;
	row-gap: 5px;
	margin-top: 5px;
	margin-bottom: 5px;
	padding: 5px;
	max-width: 200px !important;
	min-width: 200px !important;
	min-height: 470px !important;
	max-height: 470px !important;
	border: var(--gray) 3px solid;
	border-radius: 10px;
	box-sizing: border-box;
	box-shadow: rgb(0 0 0 / 10%) 0px 0.5rem 0.5rem !important;
}
.buttonDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.button:nth-child(3) {
	page-break-after: always;
}

.imgBlock {
	width: 100px;
	height: 140px;
	max-height: 140px;
	max-width: 100px;
	margin: auto;
	display: flex;
}
.scale {
	margin: auto;
	color: transparent;
	transition: 0.5s;
	display: flex;
	flex-direction: column;
}
.name {
	word-wrap: break-word;
	font-weight: bold;
	font-size: 7px;
	text-align: center;
	word-break: break-all;
}
.scale:hover {
	background: var(--white);
	transform: scale(3);
	border: 1px solid #ccc;
	border-radius: 15px;
	padding: 10px;
	color: var(--black);
}

.medium {
	font-size: small;
	padding: 0 !important;
	margin: 0 !important;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.textOverflow {
	font-size: larger;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.buttonPickLine {
	display: flex;
	justify-content: center;
	align-items: center;
}
.buttonPick {
	display: flex;
	justify-content: center;
}
.flex {
	display: flex;
	justify-content: center;
	height: min-content;
	align-self: center;
}
.input {
	align-self: center;
	border: none;
	outline: none;
	text-align: center;
	width: 45px;
	height: 24px;
}
.smallButton {
	height: 35px;
	width: 35px;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	appearance: none;
}

input[type="number"],
input[type="number"]:hover,
input[type="number"]:focus {
	appearance: none;
	-moz-appearance: textfield;
}
.transparent {
	background: var(--gray);
	cursor: not-allowed;
}
.textInButton {
	visibility: visible;
}
.square {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: var(--green);
	color: var(--white);
}
@media screen and (max-width: 700px) {
	.gridBlock {
		box-sizing: border-box;
		padding: 5px;
	}
	.buttonPickLine {
		flex-direction: row;
	}
	.imgLine {
		display: none;
		visibility: collapse;
	}
	.topDataLine {
		word-wrap: break-word;
		word-break: break-all;
		font-size: small;
	}
	.td {
		font-size: small;
		word-break: break-all;
	}
	.buttonLine {
		font-size: small;
		max-width: 150px;
		min-width: unset;
		width: fit-content;
	}
	.admin {
		visibility: collapse !important;
		display: none !important;
	}
	.textInButton {
		display: none;
	}
}
