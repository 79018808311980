.sidebar {
	background: #212934;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5rem 1rem !important;
	height: 70px;
	color: var(--white);
	display: flex;
	flex-direction: row;
	align-items: center;
}

.content {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	max-width: 1920px;
	height: 100%;
	width: 100%;
}

.logo {
	width: 100%;
	height: 55px;
}

.logoBurger {
	margin: auto;
	height: 55px;
}

.nav {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.nav a {
	margin-right: 30px;
	color: var(--white);
	font-weight: 300;
	font-size: 18px;
}

.nav a:hover {
	color: var(--orange);
}

.nav a span {
	background: var(--gray-light);
	padding: 3px;
	text-align: center;
	border: 0;
	border-radius: 50%;
	color: var(--black);
	width: 20px;
	margin-left: 5px;
}

.profile {
	font-weight: 300;
	font-size: 18px;
	cursor: pointer;
}

.profile:hover {
	color: var(--orange);
}

.dropdown {
	margin-right: 30px;
	position: relative;
	display: inline-block;
}

.dropdownContent {
	display: none;
	position: absolute;
	background-color: #212934;
	z-index: 1;
	right: 0;
	padding: 10px;
	border: 0;
	border-radius: 0 0 10px 10px;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5rem 1rem !important;
	width: 180px;
}

.dropdown:hover .dropdownContent {
	display: flex;
	flex-direction: column;
}

.dropdownContent button {
	margin: 5px 0;
}

.sidebarBlock {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	background-color: #212934;
	overflow-x: hidden;
	transition: 0.5s;
	padding: 20px 0;
}

.closeBtn {
	position: absolute;
	top: 0;
	right: 25px;
	font-size: 36px;
	margin-left: 50px;
}

.openbtn {
	cursor: pointer;
	background-color: transparent;
	color: white;
	border: none;
	margin-left: 20px;
	/* position: absolute;
	top: 20px;
	left: 20px; */
}

.openbtn:hover {
	background-color: #444;
}

#main {
	transition: margin-left 0.5s;
	padding: 16px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
	.sidebar {
		padding-top: 15px;
	}
	.sidebar a {
		font-size: 18px;
	}
}

@media (min-width: 600px) {
	.sidebarBlock,
	.openbtn,
	.logoBurger {
		display: none;
	}
}

@media (max-width: 599px) {
	.content {
		display: none;
	}

	.nav {
		flex-direction: column;
	}

	.dropdownContent {
		padding-top: 10px;
		right: -60px;
		box-shadow: rgba(204, 204, 204, 0.15) 0px 0.5rem 1rem !important;
	}

	a {
		margin: 5px 0;
	}
}
