.field {
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 5px;
	box-sizing: border-box;
	margin-left: 15px;
}
.fileInput {
	border: 2px solid var(--gray);
	padding: 10px;
	cursor: pointer;
}
.centerWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	text-align: center;
	padding: 20px;
	box-sizing: border-box;
}
.imgPreview {
	text-align: center;
	height: 200px;
	width: 100%;
	max-width: 300px;
	border: 2px solid var(--gray);
	border-radius: 0.4rem;
	margin: auto;
}

.imgPreview img {
	height: 100%;
}
.grid {
	display: grid;
	margin: 15px 0;
	grid-template-columns: 1fr auto;
	column-gap: 20px;
}
.previewText {
	width: 100%;
	margin-top: 20px;
}

.submitButton {
	height: max-content;
	width: 100px;
	padding: 10px;
	margin-left: 10px;
	text-align: center;
	background: white;
	border: 2px solid var(--gray);
	border-radius: 0.4rem;
	font-size: 15pt;
	font-weight: 700;
	cursor: pointer;
}
.submitButton:hover {
	opacity: 70%;
}

.centerText {
	text-align: center;
	width: 500px;
}

.field input,
.field select {
	width: 100%;
	box-sizing: border-box;
}
