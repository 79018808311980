.wrapper {
	display: grid;
	grid-template-columns: auto minmax(320px, 1920px) auto;
	grid-template-rows: 67px 1fr;
	grid-template-areas:
		". sidebar ."
		". body ."
		". footer .";
	min-height: 100vh;
}

.sidebar {
	grid-area: sidebar;
}

.body {
	margin-top: 20px;
	grid-area: body;
	padding: 0 20px;
}

.footer {
	grid-area: footer;
}

@media (min-width: 1920px) {
	.wrapper {
		grid-template-areas:
			"sidebar sidebar sidebar"
			". body ."
			"footer footer footer";
	}
}
