.admin {
	background: var(--white);
	padding: 20px;
	border: 0;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
}

.admin button {
	width: 250px;
	margin: 5px 0;
}
