.content {
	display: flex;
	flex-direction: column;
	width: 50%;
	justify-content: center;
	height: 100%;
	margin: auto;
	padding-bottom: 30px;
}

.changePassword {
	display: flex;
	flex-direction: column;
}

.changePassword h1 {
	text-align: center;
	font-size: 30px;
}

.required::after {
	content: "*";
	color: red;
}

.inputBlock {
	display: flex;
	flex-direction: column;
}

.inputBlock label {
	margin: 10px 0;
}

.errorInput p {
	margin-top: 2px;
}

.errorInput p,
.errorInput label {
	color: red;
}

.errorInput input {
	border: 1px solid red;
}

.btnBlock {
	display: flex;
	flex-direction: row;
	margin-top: 20px;
}

.btnBlock button {
	width: 100%;
	margin: 0 20px;
}

@media (max-width: 600px) {
	.content {
		width: max-content;
		max-width: 100%;
	}
}
