.button {
	padding: 5px 10px;
	border: 0;
	border-radius: 10px;
	cursor: pointer;
}

.red {
	background: var(--red);
	color: var(--white);
}

.green {
	background: var(--green);
	color: var(--white);
}

.orange {
	background: var(--orange);
	color: var(--white);
}

.button:hover {
	opacity: 0.8;
}
